@tailwind base;
@tailwind components;
@tailwind utilities;
body{
    background:#212121;

}
.text{
    color: #b4b4b4;
}
.border-radius{
    border-radius: 50%;
}
.gpt{
    color: #878787;
}
.gpthead{
    color: #ececec;
}
.bordergpt{
    border-color: #424242;
    
}
.out{
    outline: none;
}
   /* Hide scrollbar for Chrome, Safari and Opera */
   .no-scrollbar::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.react{
    clip-path: circle(50% at 50% 50%);
}
.js{
    clip-path: polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%);
}
.pwa{
    clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);
}
.css{
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
.tailwind{
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}
.redux{
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);}

    @media only screen and (max-width: 300px) {
        /* Media query targeting screens with a maximum width of 600 pixels */
        .media {
            width: 100%; /* Sets the background color to red for elements with the class .media */
        }
    }





    .loader{
        text-align: center;
        font-size: 20px;
        color: #222121;
        background-color: #212121;
        display: inline-block;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 400;
        position: relative;
      }
      .loader:after{
        content: '---------------------------------------------------------';
        position: relative;
        z-index: 5;
      }
      .loader:before{
        content: '';
        height: 6px;
        border: 1px solid;
        border-radius: 10px;
        width: 100%;
        position: absolute;
        bottom: 0;
        background: linear-gradient(#FF3D00 100%, transparent 0) no-repeat;
        background-size: 0% auto;
        animation: 6s lineGrow linear infinite;
      }
      
      @keyframes lineGrow {to {  background-size: 100% auto}}
      

      @keyframes slideFromRight {
        0% {
          transform: translateY(-100%);
        }
        100% {
          transform: translateY(0);
        }
      }
      
      .animate-slide-from-up {
        animation: slideFromRight 1s ease-out forwards;
      }
      